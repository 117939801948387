export const colors = {
  primary: '#4C40F7',
  primary1: '#0F2117',
  primary3: '#205638',
  primary5: '#3CAB6E',
  primary6: '#4ADC8C',

  black1: '#0C0D0E',
  black3: '#292929',
  black4: '#383838',
  black5: '#4A4A4A',
  black6: '#5C5C5C',
  black7: '#6E6E6E',
  black8: '#8A8A8A',
  black10: '#E2E2E2',
  navy: '#0D1946',

  background: '#152831',
  darkBackground: '#0C0D0E',
  colorItemText: '#B3B3B3',
  colorTextLightSolid: '#FFFFFF',
  colorTextPlaceholder: '#4A4A4A',
  colorIcon: '#6E6E6E',

  //App Colors
  Primary: '#4ADC8C',
  black: '#000',
  Grey: '#CDCDCD',
  white: '#FFF',
  MidGrey: '#333',
  DarkGrey: '#191919',
  NotChecked: '#446DFF',
  Status: '#F44',
  InProgress: '#FF9344',
  Checked: '#4ADC8C',

  primary50: '#FCFEFF',
  primary100: '#F5FAFF',
  primary300: '#A3D3FF',
  primary500: '#0086FF',
  primary600: '#006BCC',
  primary700: '#005099',
  primary800: '#003666',
  primary900: '#001B33',

  success: '#50B17F',
  successAccent: '#E9FDF2',
  error: '#EA332D',
  errorAccent: '#FDF6E9',
  warning: '#F2AB3C',
  warningAccent: '#FBE9E9',

  //secondary
  secondary: '#050505',
  secondary50: '#F1F2FC',
  secondary100: '#DDDEE8', //todo-->match this with figma
  secondary200: '#CCCDD6',
  secondary300: '#B6B7BF',
  secondary400: '#9B9CA3',
  secondary500: '#7C7C82',
  secondary600: '#37373C', //todo-->match this with figma
  secondary700: '#252527',
  secondary800: '#181819',
  secondary900: '#050505',
  textColorLight: '#FFFFFF',
  textColor: '#1A202C',
  textColorDark: '#00020B',
  textColorYellow: '#FFA800',

  secondaryButtonColor: '#2E596E',
  textColorGrey: '#ACACAC',
};
