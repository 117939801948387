import axios from '../axios';

export const getSignature = async (wallet_address: string): Promise<any> => {
  try {
    const endPoint = `/api/user/signature/?wallet_address=${wallet_address}`;
    const res = await axios.get(endPoint);
    return res.data;
  } catch (err) {
    console.log('error get signature', err);
    return Promise.reject(err);
  }
};

export const WalletLogin = async (payload: any): Promise<[]> => {
  try {
    const endPoint = '/api/user/login/';
    const res = await axios.post(endPoint, payload);

    if (!res?.data) throw 'Something went wrong';
    return res.data;
  } catch (err) {
    console.log('error post wallet', err);
    return Promise.reject(err);
  }
};

export const GetUser = async (): Promise<{ status: boolean; data: any }> => {
  try {
    const endPoint = '/api/user/current/';
    const res = await axios.get(endPoint);

    return res.data;
  } catch (err) {
    console.log('error get user', err);
    return Promise.reject(err);
  }
};
