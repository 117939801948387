import { Web3ReactProvider } from '@web3-react/core';
import { hooks as metaMaskHooks, metaMask } from '../connectors/metaMask';

import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from '../connectors/walletConnectV2';

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
];

export default function Web3ProviderWrapper({ children }) {
  return (
    <Web3ReactProvider connectors={connectors}>{children}</Web3ReactProvider>
  );
}
