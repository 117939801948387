import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import './assets/scss/App.scss';
import './assets//fonts/Visby/Fontspring-DEMO-visbycf-bold.otf';
import { Main } from './pages';
import { colors } from './utils';
import Web3ProviderWrapper from './providers/Web3Provider';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { WalletLogin, getSignature } from './api/apiCalls/user';

window.Buffer = window.Buffer || require('buffer').Buffer;

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<Main />} />)
);

function App() {
  const { primary50, success, warning, error, secondary400 } = colors;
  const { account, connector } = useWeb3React();

  const signMessage = async (message: string) => {
    try {
      const providerF = connector.provider;

      if (providerF) {
        const signature = await providerF.request({
          method: 'personal_sign',
          params: [message, account],
        });

        return signature;
      } else {
        throw new Error('MetaMask not found');
      }
    } catch (error) {
      console.error('Error signing message:', error);
      throw error;
    }
  };

  const handleToDisconnect = async () => {
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }

    localStorage.clear();
  };

  useEffect(() => {
    if (account) {
      let challenge = '';
      getSignature(account)
        .then((res: any) => {
          challenge = res?.data;

          signMessage(res?.data)
            .then((signature) => {
              const payload = {
                wallet_address: account,
                signature,
                challenge,
              };

              WalletLogin(payload)
                .then((res: any) => {
                  localStorage.setItem('accessToken', res.data.access_token);
                  localStorage.setItem('refreshToken', res.data.refresh_token);
                })
                .catch((e) => {
                  handleToDisconnect();
                });
            })
            .catch((error) => {
              console.error('Error signing message:', error);
              handleToDisconnect();
            });
        })
        .catch((e) => {
          handleToDisconnect();
        });
    }
  }, [account]);

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: primary50,
            colorText: 'white',
            colorSuccess: success,
            colorError: error,
            colorWarning: warning,
            colorTextDisabled: secondary400,
          },
          components: {
            Card: {
              colorBgContainer: colors.black3,
              colorBorder: 'transparent',
              colorBorderSecondary: 'transparent',
              borderRadius: 12,
              borderRadiusLG: 12,
              paddingLG: 24,
            },
            Button: {
              colorPrimary: colors.primary,
            },
            Select: {
              colorBorder: 'rgba(255, 255, 255, 0.6)',
              selectorBg: 'rgba(255, 255, 255, 0.02)',
              optionPadding: 10,
              optionLineHeight: 3,
              // optionActiveBg: 'rgba(255, 255, 255, 0.05)',
              optionSelectedBg: 'rgba(255, 255, 255, 0.05)',
              optionSelectedColor: '#fff',
              colorText: '#fff',
              optionSelectedFontWeight: 15,
            },
            Carousel: {
              dotWidth: 4,
              dotHeight: 4,
              dotActiveWidth: 4,
            },
            Input: {
              colorBgContainer: 'transparent',
              colorBorder: colors.black5,
              colorTextPlaceholder: colors.black5,
              colorText: colors.white,
              borderRadius: 8,
              activeBorderColor: colors.black7,
              hoverBorderColor: colors.black7,
              colorPrimaryBorderHover: colors.primary3,
              colorIcon: colors.black5,
              colorIconHover: colors.black7,
              controlHeight: 42,
            },
            InputNumber: {
              activeBorderColor: colors.primary,
              hoverBorderColor: colors.primary,
              colorBgContainer: 'transparent',
              colorBorder: colors.Grey,
              colorText: colors.black,
              fontSize: 16,
              paddingBlock: 11,
              fontFamily: 'Inter',
            },
            Menu: {
              itemSelectedBg: colors.primary,
              itemSelectedColor: colors.black,
              fontSize: 14,
              fontFamily: 'Inter',
              iconSize: 24,
              borderRadius: 4,
              itemBorderRadius: 4,
              iconMarginInlineEnd: 8,
              itemMarginInline: 0,
            },
            Form: {
              // itemMarginBottom: 12,
            },
            Checkbox: {
              colorBorder: colors.primary,
              colorPrimary: colors.primary,
              colorPrimaryHover: colors.primary,
            },
            Segmented: {
              borderRadius: 4,
              itemSelectedBg: 'transparent',
              colorBgLayout: colors.background,
              itemSelectedColor: colors.primary,
              itemColor: 'white',
              motionDurationMid: '0s',
              motionDurationSlow: '0.1s',
              controlPaddingHorizontal: 8,
              colorBorder: 'red',
            },
            Modal: {
              // contentBg: colors.DarkGrey,
              // headerBg: colors.DarkGrey,
              // titleFontSize: 24,
              colorIcon: 'white',
              padding: 32,
              paddingContentHorizontalLG: 32,
              paddingLG: 32,
              paddingMD: 32,
              paddingXS: 32,
              colorText: 'white',
              // fontWeightStrong: 400,
            },
            Spin: {
              colorPrimary: colors.primary,
            },
            Radio: {
              colorBgContainer: 'transparent',
              buttonCheckedBg: colors.primary,
              buttonSolidCheckedColor: 'black',
              buttonSolidCheckedBg: colors.primary,
              buttonSolidCheckedHoverBg: colors.primary,
              // buttonSolidCheckedActiveBg: colors.primary,
              // controlOutline: "red",
              colorBorder: '#333',
              borderRadius: 4,
              controlHeight: 48,
              fontSize: 16,
              colorWhite: colors.primary,

              //colorPrimaryBorder: "red",
              //buttonPaddingInline: 50,
            },
            Upload: {
              colorBorder: '#333',
            },
            Avatar: {
              colorTextPlaceholder: colors.colorTextPlaceholder,
            },
            Divider: {
              colorSplit: colors.black4,
              verticalMarginInline: 4,
            },
            Table: {
              headerBg: colors.darkBackground,
              rowHoverBg: colors.darkBackground,
              rowSelectedBg: colors.primary1,
              rowSelectedHoverBg: colors.primary1,
              headerBorderRadius: 0,
              borderColor: colors.black4,
              headerSplitColor: colors.black4,
              bodySortBg: colors.darkBackground,
              headerSortActiveBg: colors.darkBackground,
              colorBgContainer: colors.darkBackground,
              controlItemBgHover: colors.primary1,
              headerColor: colors.black6,
            },
            Pagination: {
              itemActiveBg: colors.darkBackground,
              itemBg: colors.darkBackground,
              colorPrimaryBorder: colors.primary,
              colorBorder: colors.black4,
            },
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </div>
  );
}

export default App;
