import { Row, Modal, Col, Select, Space, Steps } from 'antd';
import { colors } from '../../../utils/constants';
import { Text } from '../../Text';
import '../../../assets/scss/transactionModal.scss';
import { PrimaryButton } from '../../Button';
import { Stepper } from 'react-form-stepper';
import { ThreeDots } from 'react-loader-spinner';

interface TransactionStatusProps {
  mainHeading: string;
  currentStep: number;
  subHeading: string;
  hashText: string;
  hashValue: string;
  showModal: () => void;
  isModalOpen: boolean;
  handleCancel: () => void;
  transactionLink: string;
}

export const TransactionStatus = (props: TransactionStatusProps) => {
  const {
    isModalOpen,
    handleCancel,
    mainHeading,
    currentStep,
    subHeading,
    hashText,
    hashValue,
    transactionLink,
  } = props;
  const {
    textColor,
    textColorDark,
    textColorYellow,
    textColorLight,
    textColorGrey,
  } = colors;

  const connectorStyle = {
    activeBgColor: 'gray',
    // activeColor: 'blue',
    // completedColor: 'green',
  };

  return (
    <Row>
      <Modal
        centered
        open={isModalOpen}
        footer={null}
        closable={false}
        className="transaction-modal"
        onCancel={handleCancel}
      >
        <Row justify={'center'}>
          <ThreeDots
            visible={true}
            height="50"
            width="50"
            color="#fff"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
          <Col span={24} className="main-heading">
            <Text fontWeight={700} fontSize={24} color={textColorLight}>
              {mainHeading}
            </Text>
          </Col>

          <Col span={24} className="stepper">
            <Stepper
              steps={[
                { label: 'Step 1' },
                { label: 'Step 2' },
                { label: 'Step 3' },
              ]}
              activeStep={1}
              connectorStateColors={true}
              styleConfig={{
                activeBgColor: '#2E596E',
                activeTextColor: 'white',
                completedBgColor: '#071016',
                completedTextColor: 'white',
                inactiveBgColor: '#071016',
                inactiveTextColor: 'rgba(255, 255, 255, 0.20)',
                size: 30,
                circleFontSize: 15,
                labelFontSize: 1,
                borderRadius: 20,
                fontWeight: 20,
              }}
              connectorStyleConfig={{
                disabledColor: 'rgba(76, 64, 247, 0.2)',
                activeColor: '#2E596E',
                completedColor: '#071016',
                size: 2,
                style: 'dotted',
              }}
            />
          </Col>

          <Col span={24} className="sub-heading">
            <Text fontWeight={600} fontSize={18} color={textColorLight}>
              {subHeading}
            </Text>
          </Col>
          {hashValue && (
            <Col span={24} className="hash-heading">
              <Text fontWeight={600} fontSize={16} color={textColorLight}>
                {hashText}
              </Text>
            </Col>
          )}
          {hashValue && (
            <Col span={24} className="hash-text">
              <Text fontWeight={500} fontSize={14} color={textColorLight}>
                {hashValue}{' '}
                <span
                  //   onClick={() =>
                  //     window.open(`${transactionLink}/${hashValue}`, '_blank')
                  //   }
                  style={{ color: '#4A8AA9' }}
                >
                  View transaction
                </span>
              </Text>
            </Col>
          )}

          <Col span={24} className="close-btn">
            <PrimaryButton
              onClick={handleCancel}
              className="btn"
              style={{ width: '100%' }}
            >
              Close
            </PrimaryButton>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
};
