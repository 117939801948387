import axiosClient, { AxiosInstance } from 'axios';
import { store } from '../redux/store';
import { logout, setToken } from '../redux/reducers';
import { message } from 'antd';

const TIMEOUT = 35000; //15sec
const SERVER_URL =
  process.env.REACT_APP_API_HOST ||
  'https://dev.api.backend-amino.baboons.tech';

const axios: AxiosInstance = axiosClient.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  // params: clean({
  //   t: new Date().getTime(),
  //   sub_id: getCookie('sub_id'),
  // }),

  timeout: TIMEOUT,
  // transformResponse: (res) => transformKeys.toCamelCase(JSON.parse(res)),
});

const refreshToken = async () => {
  try {
    const refreshTokenString = store.getState()?.user?.refreshToken;

    if (refreshTokenString) {
      const res = await axios.get(
        `/api/auth/refresh-token?refresh_token=${refreshTokenString}`
      );
      console.log('refresh token', res.data);
      return res.data;
    }
  } catch (e) {
    console.log('Error', e);
  }
};

axios.interceptors.request.use(
  async (config) => {
    const token = store.getState()?.user?.token;
    if (token && config?.headers) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.headers['x-api-key'] =
      process.env.REACT_APP_TITLE_X_KEY ||
      '9KVvI9QM_98vtE__EYrhCgxFad-6do8fRB9050923uc';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      originalRequest.url.includes('refresh-token?') &&
      error.response.status === 401
    ) {
      message.error('Session expired, please login again!');
      store.dispatch(logout());
    } else if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const res = await refreshToken();

      store.dispatch(setToken(res));

      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${res.accessToken}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axios;
