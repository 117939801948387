import { Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { colors } from '../../utils';
import { Sidebar, Header } from '../../components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appState, toggleSidebar, userState } from '../../redux/reducers';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import Home from '../Home';
import Footer from '../../components/Footer';
import '../../assets/scss/App.scss';
import BlockSection from '../../components/blocks/BlockSection';
// import baseImg2 from '../../assets/svg/bottom_bg_swap (1).svg';

const { Content } = Layout;

const Main = () => {
  // const user = useSelector(userState);

  // return user.token ? <LoggedInRoutes /> : <NotLoggedInRoutes />;
  return <LoggedInRoutes />;
};

const LoggedInRoutes = () => {
  const dispatch = useDispatch();
  const { sidebarVisible } = useSelector(appState);
  const { md, lg, xl } = useBreakpoint();

  useEffect(() => {
    if (md && !sidebarVisible) {
      dispatch(toggleSidebar());
    }
  }, [md]);

  const getLeftMargin = () => {
    if (xl) return 325;
    if (lg) return 300;
    if (md) return 280;
    return 0;
  };

  return (
    <div
      className="Main"
      style={{
        minHeight: '100vh',
      }}
    >
      <div className="Page">
        <Header />
        <BlockSection />
      </div>
    </div>
  );
};

export default Main;
