import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const Bsc = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8" clipPath="url(#clip0_236_2003)">
      <mask
        id="mask0_236_2003"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="19"
      >
        <path d="M18 0.899414H0V18.2787H18V0.899414Z" fill="white" />
      </mask>
      <g mask="url(#mask0_236_2003)">
        <path
          d="M4.23682 9.5891L2.27615 11.5492L0.310547 9.5891L2.27121 7.62896L4.23682 9.5891ZM9.00269 4.82457L12.366 8.1869L14.3266 6.22676L9.00269 0.899414L3.6738 6.22676L5.63448 8.1869L9.00269 4.82457ZM15.7292 7.62896L13.7685 9.5891L15.7292 11.5492L17.6899 9.5891L15.7292 7.62896ZM9.00269 14.3536L5.63941 10.9913L3.67874 12.9514L9.00269 18.2788L14.3266 12.9514L12.366 10.9913L9.00269 14.3536ZM9.00269 11.5492L10.9633 9.5891L9.00269 7.62896L7.03709 9.5891L9.00269 11.5492Z"
          fill="#F0B90B"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_236_2003">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const BscIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Bsc} {...props} />
);
