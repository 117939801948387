import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

type AppState = {
  sidebarVisible: boolean;
};

const initialState: AppState = {
  sidebarVisible: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarVisible = !state.sidebarVisible;
      return state;
    },
  },
});

export const { toggleSidebar } = appSlice.actions;

export const appState = (state: RootState) => state.app;

export default appSlice.reducer;
