import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const Avalanche = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_236_1998)">
      <path d="M14.5513 3.58789H3.4375V13.689H14.5513V3.58789Z" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9939 9.49975C17.9939 14.4638 13.9672 18.4878 8.99987 18.4878C4.03261 18.4878 0.00585938 14.4638 0.00585938 9.49975C0.00585938 4.5358 4.03261 0.511719 8.99987 0.511719C13.9672 0.511719 17.9939 4.5358 17.9939 9.49975ZM6.45122 13.0765H4.70574C4.33896 13.0765 4.15779 13.0765 4.04732 13.0059C3.928 12.9286 3.85509 12.8006 3.84625 12.6592C3.83962 12.529 3.93022 12.37 4.11139 12.052L8.42121 4.46041C8.6046 4.13804 8.69739 3.97685 8.81449 3.91724C8.94043 3.85321 9.09068 3.85321 9.21662 3.91724C9.33372 3.97685 9.42653 4.13804 9.6099 4.46041L10.4959 6.00602L10.5004 6.0139C10.6985 6.35975 10.7989 6.53513 10.8428 6.7192C10.8914 6.92013 10.8914 7.13209 10.8428 7.33303C10.7986 7.5185 10.6992 7.69515 10.4981 8.04622L8.23428 12.0454L8.22842 12.0556C8.02904 12.4043 7.928 12.5811 7.78796 12.7144C7.63551 12.8602 7.45212 12.9661 7.25105 13.0258C7.06767 13.0765 6.86219 13.0765 6.45122 13.0765ZM10.8591 13.0765H13.3602C13.7292 13.0765 13.9148 13.0765 14.0254 13.0038C14.1447 12.9265 14.2197 12.7961 14.2265 12.6549C14.2328 12.5289 14.1442 12.376 13.9705 12.0766C13.9645 12.0664 13.9585 12.056 13.9524 12.0454L12.6996 9.90368L12.6854 9.87956C12.5093 9.58206 12.4205 9.43182 12.3063 9.37375C12.1805 9.30971 12.0323 9.30971 11.9064 9.37375C11.7915 9.43337 11.6988 9.59014 11.5154 9.90588L10.267 12.0477L10.2627 12.055C10.08 12.3703 9.98866 12.5278 9.99524 12.6571C10.0041 12.7984 10.077 12.9286 10.1963 13.0059C10.3046 13.0765 10.4902 13.0765 10.8591 13.0765Z"
        fill="#E84142"
      />
    </g>
    <defs>
      <clipPath id="clip0_236_1998">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const AvalancheIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Avalanche} {...props} />
);
