import heroBG from '../../assets/images/pngs/heroBG.png';
import '../../assets/scss/BlockHero.scss';
import { Swapper } from '../../pages/Home/Swapper';

export default function BlockHero() {
  return (
    <div className="BlockHeroRef">
      <div id="BlockHero" className="block-container BlockHero">
        <div className="heroBG-wrap">
          <img className="heroBG" src={heroBG} />
        </div>
        <div className="block-container-row border_LeftRight">
          <div className={'hero-item'} style={{ overflow: 'scroll' }}>
            <div className={'slide-title'}>{'ABC'}</div>
            <Swapper />
          </div>
        </div>
      </div>
    </div>
  );
}
