import React from 'react';
import { ConfigProvider, Input, InputProps, Select } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { colors } from '../utils';

export const CustomSelect = (props: any) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorBgContainer: 'rgba(255, 255, 255, 0.05)',
            colorPrimary: 'rgba(255, 255, 255, 0.05)',
            borderRadius: 28,
            colorText: colors.white,
            controlHeight: 50,
            fontSize: 16,
            colorBorder: 'rgba(225, 225, 250, 0.2)',
            colorPrimaryHover: 'rgba(225, 225, 250, 0.2)',
          },
        },
      }}
    >
      <Select {...props}>{props.children}</Select>
    </ConfigProvider>
  );
};
