import '../assets/scss/header.scss';
import logo from '../assets/images/pngs/logo.png';
import smLogo from '../assets/images/svgs/smLogo.svg';
import { PrimaryButton } from './Button';
import { Col, Row } from 'antd';
import { UserIcon } from '../assets/Icons/userIcon';

export default function Header() {
  return (
    <div className="block-container BlockHeader">
      <div className="block-container-row">
        <div className="header-line">
          <div className="line-cube" />
        </div>
        <div className="header-wrap">
          <Row>
            <Col xs={0} sm={24} md={24} lg={24}>
              <img src={logo} />
            </Col>
            <Col xs={24} sm={0} md={0} lg={0}>
              <img src={smLogo} />
            </Col>
          </Row>

          <Row className="disconnect-btn">
            <PrimaryButton>
              {/* <span
                style={{
                  color: '#00020B',
                  fontSize: '13px',
                  letterSpacing: '0.2px',
                  textTransform: 'uppercase',
                }}
              > */}
              Disconnect
              {/* </span> */}
            </PrimaryButton>
          </Row>

          <Row className="address-row">
            <Col xs={0} sm={0} md={3} lg={3}></Col>
            <Col xs={0} sm={0} md={16} lg={16} style={{ color: 'white' }}>
              0xeed9978...e372b0154
            </Col>
            <Col xs={24} sm={24} md={5} lg={5}>
              <UserIcon />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
