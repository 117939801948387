import BlockHero from './BlockHero';

const BlockSection = () => {
  return (
    <div className="BlockSection">
      <div className={'block.block-hero'}>
        <BlockHero />
      </div>
    </div>
  );
};

export default BlockSection;
