import { useEffect, useState } from 'react';
import { Card, Col, Layout, Menu, Progress, Row } from 'antd';
import type { MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { colors } from '../utils';
import { Text } from './Text';
import {
  FileImageOutlined,
  FolderOutlined,
  HomeOutlined,
  LogoutOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { logout, toggleSidebar } from '../redux/reducers';
import { store } from '../redux/store';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useDispatch } from 'react-redux';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  to?: string | undefined,
  icon?: React.ReactNode,
  onClick?: () => void
): MenuItem {
  return {
    key,
    icon,
    label: to ? <Link to={to}>{label}</Link> : label,
    onClick,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(
    'Dashboard',
    'dashboard',
    '/',
    <HomeOutlined style={{ fontSize: 16 }} />
  ),
  getItem(
    'Create',
    'create',
    '/create',
    <PlusOutlined style={{ fontSize: 16 }} />
  ),
  getItem(
    'My creations',
    'my-creations',
    '/my-creations',
    <FileImageOutlined style={{ fontSize: 16 }} />
  ),
  getItem(
    'My Collections',
    'my-collections',
    '/collections',
    <FolderOutlined style={{ fontSize: 16 }} />
  ),
];

const bottomItems: MenuItem[] = [
  getItem(
    'Search',
    'search',
    '/search',
    <SearchOutlined style={{ fontSize: 16 }} />
  ),
  getItem(
    'Help',
    'help',
    '/help',
    <QuestionCircleOutlined style={{ fontSize: 16 }} />
  ),
  getItem(
    'Logout',
    'logout',
    undefined,
    <LogoutOutlined style={{ fontSize: 16 }} />,
    () => store.dispatch(logout())
  ),
];

const { Sider } = Layout;

const Sidebar = () => {
  const dispatch = useDispatch();
  const { md, lg, xl } = useBreakpoint();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState<string>('home');

  useEffect(() => {
    if (location.pathname.includes('collections'))
      setSelectedKey('my-collections');
    else if (location.pathname.includes('create')) setSelectedKey('create');
    else if (location.pathname.includes('my-creations'))
      setSelectedKey('my-creations');
    else if (location.pathname.includes('dashboard'))
      setSelectedKey('dashboard');
    else if (location.pathname.includes('help')) setSelectedKey('help');
    else if (location.pathname.includes('search')) setSelectedKey('search');
    else setSelectedKey('dashboard');
  }, [location.pathname]);

  const getWidth = () => {
    if (xl) return 325;
    if (lg) return 300;
    return 280;
  };

  return (
    <Sider
      width={getWidth()}
      collapsible={false}
      style={{
        backgroundColor: colors.darkBackground,
        padding: '2em 1.5em 1em 1.5em',
        borderRadius: '0px 12px 12px 0px',
        height: '100vh',
        position: 'fixed',
        zIndex: 10,
        overflow: 'auto',
      }}
      className={'scroll-container'}
      trigger={null}
    >
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row align={'middle'} justify={'space-between'}></Row>
        </Col>
        <Col
          span={24}
          style={{
            padding: '0.1em 0.5em 0.5em 0.5em',
            borderBottom: `1px solid ${colors.black4}`,
          }}
        />
        <Col span={24}>
          <Row gutter={[0, 6]}>
            <Col span={24} style={{ paddingLeft: '0.5em' }}>
              <Text color={colors.black7}>General</Text>
            </Col>
            <Col span={24}>
              <Menu
                mode="inline"
                defaultSelectedKeys={['dashboard']}
                selectedKeys={[selectedKey]}
                style={{
                  backgroundColor: colors.darkBackground,
                  fontWeight: '500',
                }}
                items={items}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[0, 12]} style={{ marginTop: '1em' }}>
        <Col span={24}>
          <Card className={'card-md-padding'}>
            <Row gutter={[0, 2]}>
              <Col span={24}>
                <Text fontWeight={600} fontSize={16}>
                  Used space
                </Text>
              </Col>
              <Col span={24}>
                <Text color={colors.black8}>Recently updated 10:21 am</Text>
              </Col>
              <Col span={24}>
                <Text color={colors.black8}>November 07, 2023</Text>
              </Col>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Row>
                  <Col span={24}>
                    <Text color={colors.black8}>75%</Text>
                  </Col>
                  <Col span={24}>
                    <Progress
                      showInfo={false}
                      strokeLinecap={'round'}
                      percent={75}
                      trailColor={colors.black5}
                      style={{ height: 10 }}
                      size={['100%', 6]}
                      strokeColor={'white'}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            style={{
              backgroundColor: colors.darkBackground,
              fontWeight: '500',
              color: colors.colorItemText,
            }}
            items={bottomItems}
          />
        </Col>
      </Row>
    </Sider>
  );
};

export default Sidebar;
