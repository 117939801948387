import React from 'react';
import { Button, ButtonProps, ConfigProvider } from 'antd';
import { colors } from '../utils';
import '../assets/scss/button.scss';
import { DownloadOutlined } from '@ant-design/icons';

export const PrimaryButton = ({
  height = 43,
  borderRadius = 30,
  ...props
}: ButtonProps & {
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  const { primary50 } = colors;

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: 'linear-gradient(180deg, #FFF 0%, #E1E1FA 100%)',
            primaryColor: 'black',
            borderRadius,
            colorPrimaryHover: 'linear-gradient(180deg, #E1E1FA 0%, #FFF 100%)',
            fontSize: 13,
            fontWeight: 600,
            fontFamily: 'VisbyBold',
            controlHeight: height,
            colorTextLightSolid: '#00020B',
            defaultColor: '#00020B',
          },
        },
      }}
    >
      <Button
        type={'primary'}
        {...props}
        className="primary-btn"
        style={{
          padding: '11px 20px',
          display: 'flex',
          fontWeight: 600,
          boxShadow: 'none',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'VisbyBold',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '0.2px',
          textTransform: 'uppercase',
          ...(props.fullWidth && { width: '100%' }),
          ...(props.disabled && { backgroundColor: primary50 }),
          ...props.style,
        }}
      >
        {props.children}
      </Button>
    </ConfigProvider>
  );
};

export const OutlinedButton = ({
  height = 36,
  borderRadius = 8,
  ...props
}: ButtonProps & {
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  const { primary50 } = colors;

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: 'transparent',

            borderRadius,
            primaryColor: colors.primary,
            colorPrimaryHover: 'transparent',
            fontSize: 14,
            fontFamily: 'Inter',
            controlHeight: height,
            colorTextLightSolid: colors.primary5,
            colorIcon: colors.primary,
            colorIconHover: colors.primary5,
          },
        },
      }}
    >
      <Button
        type={'primary'}
        {...props}
        style={{
          display: 'flex',
          fontWeight: 500,
          boxShadow: 'none',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${colors.primary}`,
          ...(props.fullWidth && { width: '100%' }),
          ...(props.disabled && { backgroundColor: primary50 }),
          ...props.style,
        }}
      >
        {props.children}
      </Button>
    </ConfigProvider>
  );
};

export const SecondaryButton = ({
  height = 43,
  borderRadius = 25,
  ...props
}: ButtonProps & {
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  const { primary50 } = colors;

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: colors.secondaryButtonColor,
            borderRadius,
            primaryColor: colors.textColorLight,
            colorPrimaryHover: colors.navy,
            fontSize: 16,
            fontFamily: 'Montserrat',
            controlHeight: height,
            colorTextLightSolid: '#F7FAFC',
          },
        },
      }}
    >
      <Button
        type={'primary'}
        {...props}
        className="primary-btn"
        icon={<DownloadOutlined />}
        style={{
          padding: '11px 8px 11px 20px',
          display: 'flex',
          fontWeight: 600,
          boxShadow: 'none',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'Montserrat',
          fontSize: '13px',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '0.2px',
          ...(props.fullWidth && { width: '100%' }),
          ...(props.disabled && { backgroundColor: primary50 }),
          ...props.style,
        }}
      >
        {props.children}
      </Button>
    </ConfigProvider>
  );
};

export const SecondaryButton1 = ({
  height = 44,
  borderRadius = 8,
  ...props
}: ButtonProps & {
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  const { primary50 } = colors;

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: colors.black3,
            borderRadius,
            primaryColor: 'white',
            colorPrimaryHover: colors.black3,
            fontSize: 16,
            fontFamily: 'Inter',
            controlHeight: height,
            colorTextLightSolid: 'white',
          },
        },
      }}
    >
      <Button
        type={'primary'}
        {...props}
        style={{
          display: 'flex',
          fontWeight: 500,
          boxShadow: 'none',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${colors.black5}`,
          ...(props.fullWidth && { width: '100%' }),
          ...(props.disabled && { backgroundColor: primary50 }),
          ...props.style,
        }}
      >
        {props.children}
      </Button>
    </ConfigProvider>
  );
};

export const AuthButton = ({
  height = 44,
  borderRadius = 8,
  ...props
}: ButtonProps & {
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  const { primary50 } = colors;

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: 'transparent',
            borderRadius,
            primaryColor: 'white',
            colorPrimaryHover: 'transparent',
            fontSize: 16,
            fontFamily: 'Inter',
            controlHeight: height,
            colorTextLightSolid: colors.primary5,
            colorIcon: colors.black4,
            colorIconHover: colors.primary5,
          },
        },
      }}
    >
      <Button
        type={'primary'}
        {...props}
        style={{
          fontWeight: 500,
          boxShadow: 'none',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${colors.black4}`,
          ...(props.fullWidth && { width: '100%' }),
          ...(props.disabled && { backgroundColor: primary50 }),
          ...props.style,
        }}
      >
        {props.children}
      </Button>
    </ConfigProvider>
  );
};
