import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const Ethereum = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <rect y="0.5" width="18" height="18" rx="9" fill="#C0C0C0" />
      <path
        d="M8.9998 4L8.92969 4.25068V11.5241L8.9998 11.5977L12.2071 9.60204L8.9998 4Z"
        fill="#343434"
      />
      <path
        d="M8.9994 4L5.79199 9.60204L8.9994 11.5977V8.06741V4Z"
        fill="#8C8C8C"
      />
      <path
        d="M8.9985 12.2368L8.95898 12.2876V14.8785L8.9985 14.9999L12.2078 10.2422L8.9985 12.2368Z"
        fill="#3C3C3B"
      />
      <path
        d="M8.9994 14.9999V12.2368L5.79199 10.2422L8.9994 14.9999Z"
        fill="#8C8C8C"
      />
      <path
        d="M8.99805 11.5977L12.2054 9.60201L8.99805 8.06738V11.5977Z"
        fill="#141414"
      />
      <path
        d="M5.79199 9.60201L8.9994 11.5977V8.06738L5.79199 9.60201Z"
        fill="#393939"
      />
    </g>
  </svg>
);

export const EthereumIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Ethereum} {...props} />
);
