import { Row, Modal, Col, Select, Space, Steps } from 'antd';
import { colors } from '../../../utils/constants';
import '../../../assets/scss/transactionSuccess.scss';
import checkMark from '../../../assets/images/svgs/checkMark.svg';
import { Text } from '../../../components/Text';
import { PrimaryButton } from '../../Button';

interface TransactionStatusProps {
  mainHeading?: string;
  currentStep?: number;
  subHeading?: string;
  hashText?: string;
  hashValue?: string;
  showModal?: () => void;
  isModalOpen?: boolean;
  handleCancel: () => void;
}

export const TransactionSuccessModal = (props: TransactionStatusProps) => {
  const {
    isModalOpen,
    handleCancel,
    mainHeading,
    currentStep,
    subHeading,
    hashText,
    hashValue,
  } = props;
  const { textColor, textColorDark, textColorYellow, textColorLight } = colors;
  return (
    <Row>
      <Modal
        centered
        open={isModalOpen}
        footer={null}
        closable={false}
        className="transaction-success"
        onCancel={handleCancel}
      >
        <Row className="success-row">
          <Col span={24} className="main-heading">
            <Text
              fontWeight={600}
              fontSize={24}
              color={textColorLight}
              className="heading-text"
            >
              {mainHeading}
            </Text>
          </Col>

          <Col span={24} className="check-mark">
            <img src={checkMark} />
          </Col>
          <Col span={24} className="sub-heading">
            <Text fontWeight={500} fontSize={16} color={textColorLight}>
              {subHeading}
            </Text>
          </Col>
          <Col span={24} className="close-btn">
            <PrimaryButton
              onClick={handleCancel}
              className="btn"
              style={{ width: '100%' }}
            >
              Close
            </PrimaryButton>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
};
