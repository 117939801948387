import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const User = () => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="30" height="30" rx="15" fill="#2E596E" />
    <path
      d="M20.4141 19.4312C20.1194 18.7331 19.6916 18.0989 19.1547 17.5641C18.6195 17.0277 17.9854 16.6 17.2875 16.3047C17.2813 16.3016 17.275 16.3 17.2688 16.2969C18.2422 15.5938 18.875 14.4484 18.875 13.1562C18.875 11.0156 17.1407 9.28125 15 9.28125C12.8594 9.28125 11.125 11.0156 11.125 13.1562C11.125 14.4484 11.7579 15.5937 12.7313 16.2984C12.725 16.3016 12.7188 16.3031 12.7125 16.3062C12.0125 16.6016 11.3844 17.025 10.8454 17.5656C10.309 18.1009 9.8813 18.735 9.58598 19.4328C9.29585 20.116 9.13939 20.8485 9.12504 21.5906C9.12462 21.6073 9.12755 21.6239 9.13364 21.6394C9.13974 21.6549 9.14888 21.6691 9.16053 21.681C9.17218 21.693 9.1861 21.7025 9.20147 21.7089C9.21684 21.7154 9.23336 21.7188 9.25004 21.7188H10.1875C10.2563 21.7188 10.311 21.6641 10.3125 21.5969C10.3438 20.3906 10.8282 19.2609 11.6844 18.4047C12.5704 17.5188 13.7469 17.0312 15 17.0312C16.2532 17.0312 17.4297 17.5188 18.3157 18.4047C19.1719 19.2609 19.6563 20.3906 19.6875 21.5969C19.6891 21.6656 19.7438 21.7188 19.8125 21.7188H20.75C20.7667 21.7188 20.7832 21.7154 20.7986 21.7089C20.814 21.7025 20.8279 21.693 20.8396 21.681C20.8512 21.6691 20.8603 21.6549 20.8664 21.6394C20.8725 21.6239 20.8755 21.6073 20.875 21.5906C20.8594 20.8438 20.7047 20.1172 20.4141 19.4312ZM15 15.8438C14.2829 15.8438 13.6079 15.5641 13.1 15.0563C12.5922 14.5484 12.3125 13.8734 12.3125 13.1562C12.3125 12.4391 12.5922 11.7641 13.1 11.2562C13.6079 10.7484 14.2829 10.4688 15 10.4688C15.7172 10.4688 16.3922 10.7484 16.9 11.2562C17.4079 11.7641 17.6875 12.4391 17.6875 13.1562C17.6875 13.8734 17.4079 14.5484 16.9 15.0563C16.3922 15.5641 15.7172 15.8438 15 15.8438Z"
      fill="white"
    />
  </svg>
);

export const UserIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={User} {...props} />
);
