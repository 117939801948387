import {
  Col,
  Row,
  Form,
  Input,
  message,
  Spin,
  SelectProps,
  Select,
  Space,
} from 'antd';
import swapperArrow from '../../../assets/images/svgs/swapperArrows.svg';
import { PrimaryButton, Text } from '../../../components';
import '../../../assets/scss/swapper.scss';
import React, { useEffect, useState } from 'react';
import eth from '../../../assets/images/svgs/ethereum.svg';
import line from '../../../assets/images/svgs/line.svg';
import { colors } from '../../../utils';
import { UserIcon } from '../../../assets/Icons/userIcon';
import { CustomSelect } from '../../../components/Select';
import { PolygonIcon } from '../../../assets/Icons/polygonIcon';
import { EthereumIcon } from '../../../assets/Icons/ethereum';
import { AvalancheIcon } from '../../../assets/Icons/avalanche';
import { BscIcon } from '../../../assets/Icons/bsc';
import { TransactionStatus } from '../../../components/modals/TransactionStatus/transactionProccessing';
import { TransactionSuccessModal } from '../../../components/modals/TransactionStatus/transactionSuccess';
const { Option } = Select;

const ethOptions: SelectProps['options'] = [
  {
    label: 'Polygon',
    value: 'polygon',
    emoji: <PolygonIcon />,
    desc: 'polygon',
  },
  {
    label: 'Ethereum',
    value: 'ethereum',
    emoji: <EthereumIcon />,
    desc: 'Ethereum',
  },
];

const bscOptions: SelectProps['options'] = [
  {
    label: 'Ethereum',
    value: 'ethereum',
    emoji: <EthereumIcon />,
    desc: 'Ethereum',
  },
  {
    label: 'Polygon',
    value: 'polygon',
    emoji: <PolygonIcon />,
    desc: 'polygon',
  },
  {
    label: 'Avalanche',
    value: 'avalanche',
    emoji: <AvalancheIcon />,
    desc: 'Avalanche',
  },
  {
    label: 'BSC',
    value: 'bsc',
    emoji: <BscIcon />,
    desc: 'Bsc',
  },
];

const Swapper = () => {
  const [form] = Form.useForm();
  const { colorTextLightSolid, textColorGrey } = colors;
  const [isTransModalOpen, setIsTransModalOpen] = useState(false);
  const [isTransSuccessfull, setIsTransSuccessfull] = useState(false);

  const showTransModal = () => {
    setIsTransModalOpen(true);
  };

  const handleTransCancel = () => {
    setIsTransModalOpen(false);
  };

  const showTransSuccessModal = () => {
    setIsTransSuccessfull(true);
  };

  const handleTransSuccessCancel = () => {
    setIsTransSuccessfull(false);
  };

  const onSwapClick = () => {
    const sourceValue = form.getFieldValue('source');
    const destinationValue = form.getFieldValue('destination');
    form.setFieldValue('source', destinationValue);
    form.setFieldValue('destination', sourceValue);
  };

  return (
    <>
      <Row className="swapper">
        <Row className="swapper-form">
          <Form
            style={{ width: '100%' }}
            form={form}
            name="wrap"
            labelCol={{ flex: 'auto' }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
            requiredMark={false}
            initialValues={{
              source: 'ethereum', // Set the initial value for the "form" fields
              destination: 'bsc',
            }}
          >
            <Row style={{ width: '100%' }}>
              <Col className="heading" xs={24} sm={24} md={24} lg={24}>
                <Text
                  fontSize={36}
                  fontWeight={600}
                  color={colorTextLightSolid}
                >
                  Covey Swap
                </Text>
              </Col>
              <Col className="from-container" xs={0} sm={11} md={11} lg={12}>
                <Text
                  fontSize={18}
                  fontWeight={600}
                  color={colorTextLightSolid}
                >
                  From
                </Text>
              </Col>
              <Col xs={0} sm={2} md={2} lg={1}></Col>
              <Col className="from-container" xs={0} sm={11} md={11} lg={11}>
                <Text
                  fontSize={18}
                  fontWeight={600}
                  color={colorTextLightSolid}
                >
                  To
                </Text>
              </Col>
              <Col className="source-container" xs={24} sm={11} md={11} lg={12}>
                <Row>
                  <Col className="from-container" xs={24} sm={0} md={0} lg={0}>
                    <Text
                      fontSize={18}
                      fontWeight={600}
                      color={colorTextLightSolid}
                    >
                      From
                    </Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      label="Source Chain"
                      labelAlign="left"
                      style={{ fontFamily: 'Poppins', color: 'red' }}
                      name="source"
                      className="form-item"
                      required={true}
                      labelCol={{ span: 24 }}
                      rules={[{ required: true }]}
                    >
                      <CustomSelect className="custom-select">
                        {bscOptions.map((item: any, id: any) => {
                          return (
                            <Option
                              value={item.value}
                              key={id}
                              style={{ color: 'white', height: 'inherit' }}
                            >
                              <div
                                style={{
                                  color: 'white',
                                  height: '100%',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}
                              >
                                {item.emoji}
                                <span
                                  style={{
                                    marginLeft: '10px',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {item.desc}
                                </span>
                              </div>
                            </Option>
                          );
                        })}
                      </CustomSelect>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="line">
                  <Col xs={24} span={11}>
                    <img className="line-img" src={line} width={300} />
                  </Col>
                </Row>
                <Row className="source-chain">
                  <Text
                    className="text-area-label"
                    fontSize={18}
                    fontWeight={600}
                    color={colorTextLightSolid}
                  >
                    Enter amount
                  </Text>
                  <Input
                    className="custom-input"
                    placeholder="0 ETH"
                    type="number"
                    autoComplete="off"
                  />
                </Row>
              </Col>
              <Col xs={2} sm={2} md={2} lg={1} className="swapper-arrow">
                <img onClick={onSwapClick} src={swapperArrow} />
              </Col>
              <Col className="source-container" xs={24} sm={11} md={11} lg={11}>
                <Row>
                  <Col className="from-container" xs={24} sm={0} md={0} lg={0}>
                    <Text
                      fontSize={18}
                      fontWeight={600}
                      color={colorTextLightSolid}
                    >
                      To
                    </Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      label="Destination Chain"
                      labelAlign="left"
                      style={{ fontFamily: 'Poppins', color: 'red' }}
                      name="destination"
                      className="form-item"
                      required={true}
                      labelCol={{ span: 24 }}
                      rules={[{ required: true }]}
                    >
                      <CustomSelect
                        className="custom-select"
                        defaultValue={'bsc'}
                        // open={true}
                      >
                        {bscOptions.map((item: any, id: any) => {
                          return (
                            <Option
                              value={item.value}
                              key={id}
                              style={{ color: 'white', height: 'inherit' }}
                            >
                              <div
                                style={{
                                  color: 'white',
                                  height: '100%',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}
                              >
                                {item.emoji}
                                <span
                                  style={{
                                    marginLeft: '10px',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {item.desc}
                                </span>
                              </div>
                            </Option>
                          );
                        })}
                      </CustomSelect>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="line">
                  <Col xs={24} span={11}>
                    <img className="line-img" src={line} width={300} />
                  </Col>
                </Row>
                <Row className="source-chain">
                  <Text
                    className="text-area-label"
                    fontSize={18}
                    fontWeight={600}
                    color={colorTextLightSolid}
                  >
                    You receive
                  </Text>
                  <Input
                    className="custom-input"
                    placeholder="0 ETH"
                    type="number"
                    autoComplete="off"
                  />
                </Row>
              </Col>
            </Row>
            <Row className="fee">
              <Text fontSize={12} fontWeight={500} color={textColorGrey}>
                Fee: 0.00 ETH
              </Text>
            </Row>
            <Row className="transfer-btn">
              <Col span={24}>
                <PrimaryButton
                  onClick={showTransModal}
                  style={{ width: '100%' }}
                >
                  Transfer
                </PrimaryButton>
              </Col>
            </Row>
          </Form>
        </Row>
      </Row>
      <TransactionStatus
        mainHeading={'Processing'}
        currentStep={1}
        subHeading={'Transaction confirming on [Blockchain]'}
        hashText="Transaction hash:"
        hashValue={
          '0x1944e6cddfbbcdf6253df24b90a7478d288681442e73a6bf59d90dfa67edfe8d'
        }
        isModalOpen={isTransModalOpen}
        showModal={showTransModal}
        handleCancel={handleTransCancel}
        transactionLink={'link'}
      />
      <TransactionSuccessModal
        isModalOpen={isTransSuccessfull}
        showModal={showTransSuccessModal}
        handleCancel={handleTransSuccessCancel}
        mainHeading="Transaction completed"
        subHeading="Please check your wallet on the destination chain"
      />
    </>
  );
};

export default Swapper;
