import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/index.scss';
import App from './App';
import { store } from './redux/store';
import persistStore from 'redux-persist/es/persistStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import Web3ProviderWrapper from './providers/Web3Provider';

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // @ts-ignore
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Web3ProviderWrapper>
        <App />
      </Web3ProviderWrapper>
    </PersistGate>
  </Provider>
);

reportWebVitals();
